<script>
import useVuelidate from '@vuelidate/core'
import {maxLength, required} from '@vuelidate/validators'

/* COMPOSANTS */
import AkFormView from "@components/layout/AkFormView";
import AkInputText from "@components/input/AkInputText";
import AkInputTextArea from "@components/input/AkInputTextArea";
import AkTime from "@components/input/AkTime";
import AkDialog from "@components/general/AkDialog";
import Toast from "primevue/toast";
import AkCheckboxButton from "@components/input/AkCheckboxButton";
import AkYesNoButton from "@components/input/AkYesNoButton";
import AkLabel from "@components/general/AkLabel";

/* MIXINS */
import randomRef from "@mixins/randomRef";
import eventMixin from "@mixins/eventMixin";
import dateFormatter from "@mixins/dateFormatter";
import multiLineMixin from "@mixins/multiLineMixin";
import urlMixin from "@mixins/urlMixin";
import roleMixin from "@mixins/roleMixin";
import utilsMixin from "@mixins/utilsMixin";
import imgMixin from "@mixins/imgMixin";
import typeVhConst from "@mixins/const/typeVhConst";

/* SERVICES */
import forecastSheetService from "@services/forecastSheetService";
import forecastCommentService from "@services/forecastCommentService";
import rolePermissionService from "@services/rolePermissionService";
import fileService from "@services/fileService";

export default {
  components: {
    AkFormView,
    AkInputTextArea,
    AkInputText,
    AkTime,
    AkDialog,
    Toast,
    AkCheckboxButton,
    AkYesNoButton,
    AkLabel
  },
  mixins: [randomRef, eventMixin, dateFormatter, roleMixin, multiLineMixin, urlMixin, utilsMixin, imgMixin, typeVhConst],
  setup() {
    return {v$: useVuelidate()}
  },
  metaInfo() {
    return {
      title: "forecast_sheet.details",
    }
  },
  data() {
    return {
      sheetId: undefined,
      files: [],
      current: {},
      motif: "",
      forecastObservation: "",
      interventionObservation: "",
      validationComment: "",
      date: null,
      forecastRows: [],
      events: [],
      comments: [],
      commentMessage: '',
    }
  },
  validations() {
    return {
      commentMessage: {required, maxLength: maxLength(5000)},
    }
  },
  mounted() {
    this.getRef().showTotalLoader();
    this.sheetId = this.$route.params.id;

    let msg = this.$route.query.msg;
    if (msg && msg !== "") this.getRef().success(msg);

    let p1 = forecastSheetService.findByIdWithRowsForView(this.sheetId);
    p1.then(data => {
      this.current = data.forecastSheet;
      this.forecastRows = data.forecastRows;
      this.events = this.getSheetEvents(this.current);
    });

    let p2 = fileService.findAllByForecastSheetId(this.sheetId);
    p2.then(data => this.files = data);

    let p3 = forecastCommentService.findAll({forecastSheetId: this.sheetId});
    p3.then(data => this.comments = data);

    let p4 = rolePermissionService.authorizeAccessByPermissions([
      this.permissionConst.forecast.view,
      this.permissionConst.forecast.viewOther,
      this.permissionConst.forecast.viewValidated,
      this.permissionConst.forecast.viewValidatedOther,
      this.permissionConst.forecast.viewArchived,
      this.permissionConst.forecast.viewArchivedOther
    ], 'or');

    Promise.all([p1, p2, p3, p4]).then(() => {
      this.initData();
      this.getRef().hideLoader();
    });
  },
  methods: {
    initData() {
      this.motif = this.getTextParsed(this.current.motif);
      this.forecastObservation = this.getTextParsed(this.current.forecastObservation);
      this.interventionObservation = this.getTextParsed(this.current.interventionObservation);
      this.validationComment = this.getTextParsed(this.current.validationComment);
      this.date = this.formatDate(this.current.date);
    },
    downloadSheet() {
      forecastSheetService.download(this.current);
    },
    validateSheet() {
      this.getRef().resetMessages();
      let dto = {
        forecastSheet: this.current,
        forecastRows: this.forecastRows,
        validated: true
      }
      forecastSheetService.update(dto).then(data => {
        this.current = data.forecastSheet;
        this.events = this.getSheetEvents(this.current);
        this.getRef().success(this.$t("forecast_sheet.validated"));
      }).catch(e => {
            this.getRef().error(this.$t("error." + e.response.data.message));
          }
      );
    },
    createComment() {
      this.getRef().resetMessages();
      this.v$.$touch();
      if (this.v$.$error) return;

      let dto = {
        forecastSheetId: this.current.id,
        message: this.commentMessage,
      };

      this.getRef().showTotalLoader();
      forecastCommentService.create(dto).then(data => {
        data.creatorFirstName = this.currentUser.firstName;
        data.creatorLastName = this.currentUser.lastName;
        this.insertFirst(this.comments, data);
        this.commentMessage = '';

        this.$toast.add({severity: 'success', summary: this.$t("forecast_sheet.commented"), life: 3000});
        this.$refs.dialogComment.hide();
      }).finally(() => {
        this.getRef().hideLoader();
      });
    },
    openDialogValidate() {
      this.$refs.dialogValidate.show();
    },
    openDialogComment() {
      this.commentMessage = '';
      this.$refs.dialogComment.show();
    },
  },
  computed: {
    agerList() {
      return [{id: this.current.agerId, label: this.current.agerLabel}]
    },
    uerList() {
      return [{id: this.current.uerId, label: this.current.uerLabel}]
    },
    ceiList() {
      return [{id: this.current.ceiId, label: this.current.ceiLabel}]
    },
    riName() {
      return this.current.creatorFirstName + " " + this.current.creatorLastName;
    },
    currentDateFriday() {
      return this.findDayOfWeek(this.current.date) === 5;
    },
    meteoFilesToShow() {
      return this.files.filter(file => file.type === 'METEO');
    },
    additionalFilesToShow() {
      return this.files.filter(file => file.type === 'ADDITIONAL_FILE');
    }
  },
}
</script>

<template v-if=!roleLoading>
  <Toast/>
  <AkFormView :ref="ref" :title="$t('forecast_sheet.details')">
    <template v-slot:action>
      <RouterLink v-if="this.canEditForecast(current) && this.current.id !== undefined"
                  :to="{ name: 'forecastUpdate', params:{id:this.current.id}}"
                  class="btn btn-inverse-primary btn-xs">
        <i class="fe fe-edit pr-1"/>{{ $t('forecast_sheet.update_this') }}
      </RouterLink>
      <button v-if="null !== current.dateValidated"
              class="btn btn-inverse-primary btn-xs ml-1"
              @click="downloadSheet">
        <i class="fe fe-download pr-1"/>{{ $t('forecast_sheet.download') }}
      </button>
    </template>
    <template v-slot:form>
      <div class="form-row">
        <AkInputText v-model="date" :disabled="true" :label="$t('date')" class-name="col-md-3"/>
        <AkInputText v-model="current.agerLabel" :disabled="true" :label="$t('ager_label')" class-name="col-md-3"/>
        <AkInputText v-model="current.uerLabel" :disabled="true" :label="$t('uer_label')" class-name="col-md-3"/>
        <AkInputText v-model="current.ceiLabel" :disabled="true" :label="$t('cei_label')" class-name="col-md-3"/>
      </div>
      <div v-if="current.typeVh" class="form-row">
        <AkCheckboxButton v-model="current.typeVh" :disabled="true" :label="$t('type_vh_label')" :multiple="false"
                          :options="typeVhConst" class-name="col-md-6"/>
      </div>
      <div class="form-row">
        <AkInputText
            v-model="riName"
            :disabled="true"
            :label="$t('forecast_sheet.creator_name')"
            class-name="col-md-6"/>
        <AkInputText
            v-model="current.tel"
            :disabled=true
            :label="$t('forecast_sheet.creator_tel')"
            class-name="col-md-6"
            placeholder=" "/>
      </div>
      <h5 class="mt-2">{{ $t('forecast_sheet.weather_forecast') }}</h5>
      <div class="form-row" style="padding-bottom: 20px">
        <div class="col-lg-12">
          <div class="table-responsive forecast-table">
            <table>
              <thead>
              <tr>
                <th>{{ $t('forecast_sheet.hours') }}</th>
                <th v-for="row in forecastRows" :key="row.order">
                  <input :value="row.hour" disabled maxlength="5" size="5"/>
                </th>
              </tr>
              </thead>
              <tbody>
              <tr>
                <td>{{ $t('forecast_row.air_temp') }}</td>
                <td v-for="row in forecastRows" :key="row.order">
                  <input :value="row.airTemp" disabled max="50" min="-50" step="0.1" type="number"/>
                </td>
              </tr>
              <tr>
                <td>{{ $t('forecast_row.ground_temp') }}</td>
                <td v-for="row in forecastRows" :key="row.order">
                  <input :value="row.groundTemp" disabled max="50" min="-50" step="0.1" type="number"/>
                </td>
              </tr>
              <tr>
                <td>{{ $t('forecast_row.humidity') }}</td>
                <td v-for="row in forecastRows" :key="row.order">
                  <input :value="row.humidity" disabled max="100" min="0" step="0.1" type="number"/>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <template v-if="meteoFilesToShow.length > 0">
        <h5 class="mt-2">{{ $t('forecast_sheet.files') }}</h5>
        <div class="form-row">
          <template v-for="file in meteoFilesToShow" :key="file.id">
            <div v-if="file.mimetype.includes('image/')" class="col-md-4" style="display: flex;">
              <a :href=generateUrlForFile(file) style="margin: 5px 0 5px 5px;" target="_blank">
                <img :alt=file.name :src=generateUrlForFile(file) style="min-height: 100%; max-width: 100%;"/>
              </a>
            </div>
          </template>
        </div>
        <div class="form-row">
          <template v-for="file in meteoFilesToShow" :key="file.id">
            <div v-if="!file.mimetype.includes('image/')" class="col-md-4" style="display: flex;">
              <a :href=generateUrlForFile(file) class="card file-card">
                <img :alt="getThumbnailAlt(file.mimetype)" :src="getThumbnail(file.mimetype)" style="max-width: 30px;"/>
                <p style="margin-left: 5px; margin-right: 5px;">{{ file.name }}</p>
              </a>
            </div>
          </template>
        </div>
      </template>
      <div class="form-row">
        <div class="form-group col-md-12">
          <AkLabel>{{ $t('forecast_sheet.forecast_observation') }}</AkLabel>
          <div class="form-control" style="background: #e9ecef; color: #495057; opacity: 1; display: table;"
               v-html="forecastObservation"></div>
        </div>
      </div>
      <h5 class="mt-2">{{ $t('forecast_sheet.intervention_forecast') }}</h5>
      <div class="form-row">
        <div class="col-md-6">
          <div class="form-row">
            <AkTime
                v-model="current.patrolStart"
                :disabled=true
                :label="$t('forecast_sheet.patrol_start')"
                class-name="col-md-12"
                placeholder=" "/>
            <AkTime
                v-model="current.precurativeStart"
                :disabled=true
                :label="$t('forecast_sheet.precurative_start')"
                class-name="col-md-12"
                placeholder=" "/>
            <AkTime
                v-model="current.curativeStart"
                :disabled=true
                :label="$t('forecast_sheet.curative_start')"
                class-name="col-md-12"
                placeholder=" "/>
          </div>
        </div>
        <div class="col-md-6">
          <div>
            <div class="form-group col-md-12">
              <AkLabel>{{ $t('forecast_sheet.intervention_observation') }}</AkLabel>
              <div class="form-control" style="background: #e9ecef; color: #495057; opacity: 1; display: table;"
                   v-html="interventionObservation"></div>
            </div>
          </div>
        </div>
      </div>
      
      <div v-if="currentDateFriday">
        <h5 class="mt-2">{{ $t('forecast_sheet.weekend_activation') }}</h5>
        <div class="form-row">
          <AkYesNoButton v-model="current.weekend" 
            :disabled="true" 
            :label="$t('forecast_sheet.weekend')"
            :multiple="false" 
            class-name="col-md-2"/>
        </div>
        <div class="form-row">
          <div class="col-md-12">
            <AkLabel>{{ $t('forecast_sheet.weekend_motif') }}</AkLabel>
            <div class="form-control" 
               style="background: #e9ecef; color: #495057; opacity: 1; display: table;"
               v-html="motif">
            </div>
          </div>
        </div>
        <template v-if="additionalFilesToShow.length > 0">
          <h5 class="mt-2">{{ $t('forecast_sheet.additional_files') }}</h5>
          <div class="form-row">
            <template v-for="file in additionalFilesToShow" :key="file.id">
              <div v-if="file.mimetype.includes('image/')" class="col-md-4" style="display: flex;">
                <a :href=generateUrlForFile(file) style="margin: 5px 0 5px 5px;" target="_blank">
                  <img :alt=file.name :src=generateUrlForFile(file) style="min-height: 100%; max-width: 100%;"/>
                </a>
              </div>
            </template>
          </div>
          <div class="form-row">
            <template v-for="file in additionalFilesToShow" :key="file.id">
              <div v-if="!file.mimetype.includes('image/')" class="col-md-4" style="display: flex;">
                <a :href=generateUrlForFile(file) class="card file-card">
                  <img :alt="getThumbnailAlt(file.mimetype)" :src="getThumbnail(file.mimetype)" style="max-width: 30px;"/>
                  <p style="margin-left: 5px; margin-right: 5px;">{{ file.name }}</p>
                </a>
              </div>
            </template>
          </div>
        </template>
      </div>
      
      <div v-if="current.dateValidated" class="form-row">
        <div class="form-group col-md-12">
          <AkLabel>{{ $t('forecast_sheet.validation_comment') }}</AkLabel>
          <div class="form-control" style="background: #e9ecef; color: #495057; opacity: 1; display: table;"
               v-html="validationComment"></div>
        </div>
      </div>
      <div class="form-row">
        <div class="col-md-4">
          <h5 class="mt-2">{{ $t('forecast_sheet.history') }}</h5>
          <div class="form-row">
            <Timeline :value="events">
              <template #opposite="slotProps">
                <small class="p-text-secondary">{{ slotProps.item.dateDisplay }}</small>
              </template>
              <template #content="slotProps">
                {{ slotProps.item.name }}
              </template>
            </Timeline>
          </div>
        </div>
        <div class="col-md-8">
          <button v-if="this.canCommentForecast(this.current)"
                  class="btn btn-info float-right ml-1"
                  @click="openDialogComment">
            <i class="fe fe-message-square pr-1"/>{{ $t('forecast_sheet.add_comment') }}
          </button>
          
          <div v-if="comments.length > 0">
            <h5 class="mt-2">{{ $t('forecast_sheet.comments') }}</h5>
            <div class="form-row">
              <Timeline :value="comments">
                <template #opposite="slotProps">
                  <small class="p-text-secondary">{{ displayDateTime(slotProps.item.dateCreated) }}</small>
                </template>
                <template #content="slotProps">
                  <h6>{{ slotProps.item.creatorFirstName }} {{ slotProps.item.creatorLastName }}</h6>
                  <div style="padding-bottom: 1rem;" v-html="this.getTextParsed(slotProps.item.message)"></div>
                </template>
              </Timeline>
            </div>
          </div>
        </div>
      </div>
      <button
          v-if="this.canValidateForecast(current)"
          class="btn btn-success float-right"
          @click=openDialogValidate()>
        {{ $t('validate') }}
      </button>
    </template>

    <template v-slot:extra>
      <AkDialog
          ref="dialogValidate"
          :auto-hide-on-validate="true"
          :cancel-label="$t('no')"
          :title="$t('link_sheet.delete_dialog')"
          :validate-label="$t('yes')"
          width="450px"
          @validate="this.validateSheet()">
        <div class="confirmation-content">
          <i class="fe fe-alert-triangle mr-1" style="font-size: 2rem"/>
          <span>{{ $t('forecast_sheet.confirm_validation') }}</span>
        </div>
        <div class="form-row">
          <AkInputTextArea v-model="current.validationComment"
             :label="$t('forecast_sheet.comment')"
             class-name="col-12"
             placeholder=" "/>
        </div>
      </AkDialog>

      <AkDialog
          ref="dialogComment"
          :cancel-label="$t('no')"
          :title="$t('forecast_sheet.comment_dialog')"
          :validate-label="$t('yes')"
          width="450px"
          @validate="this.createComment()">
        <div class="form-row">
          <AkInputTextArea v-model="commentMessage"
              :label="$t('forecast_sheet.comment')"
              :validator="v$.commentMessage"
              class-name="col-12"
              placeholder=" "/>
        </div>
      </AkDialog>
    </template>
  </AkFormView>
</template>

<style>
.file-card {
  align-items: center;
  flex-direction: row;
  padding: 5px 5px;
  margin: 5px 0;
  border-radius: .25rem;
  width: -webkit-fill-available;
}
</style>